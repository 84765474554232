$('#fonassi').on('click', function(){
    $.fancybox.open([
        {
            src:  'http://127.0.0.1:8000/images/talent-2020/1-fonassi/francesco-fonassi-salvatici-1.jpg',
        },
        {
            src:  'http://127.0.0.1:8000/images/talent-2020/1-fonassi/francesco-fonassi-salvatici-2.jpg',
        },
        {
            src:  'http://127.0.0.1:8000/images/talent-2020/1-fonassi/francesco-fonassi-salvatici-3.jpg',
        }
    ], {
        loop: false
    });

});
